<template>
  <nav class="align-items-center d-flex font-weight-light h4 justify-content-between mb-0 py-4">
    <ol class="breadcrumb mb-0">
      <li
        v-for="(item, index) in concatenatedItems"
        :key="index"
        class="breadcrumb-item"
        :class="{ active: index === concatenatedItems.length - 1 }"
      >
        <RouterLink
          v-if="index !== concatenatedItems.length - 1"
          :to="item.to"
        >
          {{ item.text }}
        </RouterLink>
        <span v-else>
          {{ item.text }}
        </span>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { computed } from "vue";
import { EMISSOR_NFE, GESTAO } from "@/constants/profiles.js";
import authStore from "@/store/auth.js";

const props = defineProps({ items: { required: true, type: Array } });

const ocultarPorPerfil = {
  [EMISSOR_NFE]: ["Faturamento", "Estoque", "Compras", "Logística", "RH", "Dashboard", "Docs. Fiscais"],
  [GESTAO]: ["Logística", "RH", "Dashboard", "Docs. Fiscais"],
};

const home = [{ text: "Início", to: "/home" }];

const concatenatedItems = computed(() => {
  const perfil = authStore.getCurrentUser().role;
  const itens = home.concat(props.items);

  if (ocultarPorPerfil[perfil]) {
    const modulosEscondidos = ocultarPorPerfil[perfil];
    return itens.filter((item) => !modulosEscondidos.includes(item.text));
  }

  return itens;
});
</script>
