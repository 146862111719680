import { ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA } from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/controladoria/cenarios/form-cenarios-edit.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA] },
    name: "controladoria.editar-cenario",
    path: "/controladoria/editar-cenario/:id",
  },
  {
    component: () => import("@/components/controladoria/cenarios/cenarios.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA] },
    name: "controladoria.cenarios",
    path: "/controladoria/cenarios/gerenciar",
  },
  {
    component: () => import("@/components/controladoria/cenarios/cenario-itens.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA] },
    name: "controladoria.cenario-itens",
    path: "/controladoria/cenarios/:id/itens",
  },
  {
    component: () => import("@/components/controladoria/cenarios/cenario-produtos.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA] },
    name: "controladoria.cenario-produtos",
    path: "/controladoria/cenarios/:id/produtos",
  },
  {
    component: () => import("@/components/controladoria/cenarios/home-cenarios.vue"),
    meta: { authorize: [CONTROLADORIA] },
    name: "controladoria.home.cenarios",
    path: "/controladoria/cenarios",
  },
  {
    component: () => import("@/components/controladoria/relatorios/home-relatorios.vue"),
    meta: { authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA] },
    name: "controladoria.home.relatorios",
    path: "/controladoria/relatorios",
  },
  {
    component: () => import("@/components/home/MenuControladoria.vue"),
    meta: { authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA] },
    name: "controladoria.home.controladoria",
    path: "/controladoria",
  },
  {
    component: () => import("@/components/controladoria/cenarios/form-cenarios.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA] },
    name: "controladoria.novo-cenario",
    path: "/controladoria/cenarios/novo",
  },
  {
    component: () => import("@/components/controladoria/relatorios/rentabilidade.vue"),
    meta: { authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA] },
    name: "controladoria.relatorios.rentabilidade",
    path: "/controladoria/relatorios/rentabilidade",
  },
  {
    component: () => import("@/components/controladoria/relatorios/rastreamento-produtos.vue"),
    meta: { authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA] },
    name: "controladoria.relatorios.rastreamento-produtos",
    path: "/controladoria/relatorios/rastreamento-produtos",
  },
  {
    component: () => import("@/components/controladoria/relatorios/rastreamento-materia-prima.vue"),
    meta: { authorize: [ADMIN_SYS, CONSULTA_FINANCEIRO, CONTROLADORIA] },
    name: "controladoria.relatorios.rastreamento-materia-prima",
    path: "/controladoria/relatorios/rastreamento-materia-prima",
  },
];
