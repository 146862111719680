import { EMISSOR_NFE, GESTAO, OPERACIONAL } from "@/constants/profiles.js";

export default [
  //CONFIGURACOES

  {
    component: () => import("@/components/minha-conta/minha-conta.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "minha-cont",
    path: "/config/minha-conta",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/home-usuarios.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "configuracoes.usuario",
    path: "/config/usuarios",
  },
  {
    component: () => import("@/components/faturamento/configuracoes/api/form-ibpt.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "config.ibpttoken",
    path: "/config/token-ibpt",
  },
  {
    component: () => import("@/components/configuracoes/nota-fiscal/ajuste-pcredsn.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "config.ajustepcredsn",
    path: "/config/ajuste-pcredsn",
  },
  {
    component: () => import("@/components/faturamento/configuracoes/templates/editor-template.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    path: "/config/nota-fiscal/templates/cadastro",
    name: "config.templates.cadastro",
  },
  {
    component: () => import("@/components/faturamento/configuracoes/templates/lista-de-templates.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "config.templates",
    path: "/config/nota-fiscal/templates",
  },
  {
    path: "/config/tarifas-cartao",
    name: "tarifa-credito.cadastro.comercio",
    component: () => import("@/components/faturamento/nfe/editor-tarifa-cartao-credito.vue"),
  },

  //CADASTROS

  {
    component: () => import("@/components/home/MenuCadastros.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastros.home",
    path: "/cadastros",
  },
  {
    component: () => import("@/components/faturamento/cadastros/cliente/editor-cliente-geral.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.clientes.cadastro",
    path: "/cadastros/clientes/novo",
  },
  {
    component: () => import("@/components/faturamento/cadastros/cliente/editor-cliente-geral.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.clientes.edit",
    path: "/cadastros/clientes/editar/:id",
  },
  {
    component: () => import("@/components/faturamento/cadastros/cliente/clientes.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.clientes",
    path: "/cadastros/clientes",
  },
  {
    component: () => import("@/components/estoque/cadastros/produto/produtos.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.produtos",
    path: "/cadastros/produtos",
  },
  {
    component: () => import("@/components/estoque/cadastros/produto/editor-produto-geral.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.produtos.cadastro",
    path: "/cadastros/produtos/cadastro",
  },
  {
    component: () => import("@/components/estoque/cadastros/produto/historico-estoque.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.produtos.estoque.historico",
    path: "/cadastros/produtos/historico/:codpro",
  },
  {
    component: () => import("@/components/estoque/cadastros/produto/editor-produto-geral.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.produtos.edit",
    path: "/cadastros/produtos/editar/:id",
  },
  {
    component: () => import("@/components/estoque/cadastros/grupo-produto/grupos-produtos.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.estoque.produtos.grupos",
    path: "/cadastros/produtos-grupos",
  },
  {
    component: () => import("@/components/logistica/cadastros/transportadora/form-transportadora.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.transportadoras.cadastro",
    path: "/cadastros/transportadoras/cadastro",
  },
  {
    component: () => import("@/components/logistica/cadastros/transportadora/form-transportadora.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.transportadoras.edit",
    path: "/cadastros/transportadoras/editar/:id",
  },
  {
    component: () => import("@/components/logistica/cadastros/transportadora/transportadoras.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.transportadoras",
    path: "/cadastros/transportadoras",
  },
  {
    component: () => import("@/components/compras/cadastros/fornecedor/form-fornecedor.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.fornecedores.cadastro",
    path: "/cadastros/fornecedores/cadastro",
  },
  {
    component: () => import("@/components/compras/cadastros/fornecedor/form-fornecedor.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.fornecedores.edit",
    path: "/cadastros/fornecedores/editar/:id",
  },
  {
    component: () => import("@/components/compras/cadastros/fornecedor/fornecedores.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "cadastro.fornecedores",
    path: "/cadastros/fornecedores",
  },
  {
    path: "/cadastros/servicos",
    name: "os.novo.comercio",
    component: () => import("@/components/faturamento/cadastros/servico/novoservico.vue"),
  },
  {
    path: "/cadastros/servicos/gerenciar",
    name: "cadastro.servico.gerenciar.comercio",
    component: () => import("@/components/faturamento/cadastros/servico/gerenciarservico.vue"),
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncionarios.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "cadastro.funcionarios",
    path: "/cadastros/funcionarios",
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncionarios.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "rh.cadastros.funcionarios.novo",
    path: "/rh/cadastros/funcionarios/novo",
    props: { showModalStaffCreate: true },
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncoes.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "cadastro.funcoes",
    path: "/cadastros/funcoes",
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncoes.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "cadastro.funcoes.novo",
    path: "/cadastros/funcoes/novo",
  },

  //PRODUTIVIDADE

  {
    path: "/produtividade/ordem-servico",
    name: "os.gerenciar.comercio",
    component: () => import("@/components/faturamento/os/gerenciar-os.vue"),
  },

  //OPERACIONAL

  {
    component: () => import("@/components/faturamento/nfe/NovaNFe.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "nota-fiscal.gerenciar.novo",
    path: "/nota-fiscal/gerenciar/novo",
  },
  {
    component: () => import("@/components/faturamento/nfe/gerenciar-nfe.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "nota-fiscal.gerenciar-nfe",
    path: "/nota-fiscal/gerenciar",
  },
  {
    component: () => import("@/components/faturamento/nfe/gerenciar-nfe-show.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "nota-fiscal.edit",
    path: "/nota-fiscal/editar/:id",
  },
  {
    component: () => import("@/components/faturamento/nfe/NovaNFe.vue"),
    path: "/nota-fiscal/gerenciar/:id/edit",
    name: "nota-fiscal.editar",
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
  },
  {
    component: () => import("@/components/faturamento/nfe/gerenciar-orc-show.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    path: "/orcamento/editar/:id",
    name: "orcamento.edit",
  },
  {
    component: () => import("@/components/dashboards/vendas/dashboard-vendas-comercio.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "total.vendas",
    path: "/total-vendas",
  },
  {
    component: () => import("@/components/compras/compras/importar-NFe.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "nota-fiscal.importar",
    path: "/nota-fiscal/importar",
  },
  {
    component: () => import("@/components/compras/compras/gerenciar-notas-importadas.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "nota-fiscal-importada.gerenciar",
    path: "/nota-fiscal-importada/gerenciar",
  },

  //SOBRE

  { component: () => import("@/components/sobre/versao.vue"), name: "sobre.versao", path: "/sobre/versao" },

  //HOMES DO GESTAO

  {
    component: () => import("@/components/home/MenuFaturamentoComercio.vue"),
    meta: { authorize: [GESTAO, OPERACIONAL] },
    name: "faturamento.comercio",
    path: "/faturament",
  },
  {
    component: () => import("@/components/home/MenuEstoqueComercio.vue"),
    meta: { authorize: [GESTAO, OPERACIONAL] },
    name: "estoque.comercio",
    path: "/estoq",
  },
  {
    component: () => import("@/components/home/MenuComprasComercio.vue"),
    meta: { authorize: [GESTAO, OPERACIONAL] },
    name: "compras.comercio",
    path: "/compra",
  },
  {
    component: () => import("@/components/home/MenuFinanceiroComercio.vue"),
    meta: { authorize: [GESTAO] },
    name: "financeiro.comercio",
    path: "/financ",
  },
  {
    component: () => import("@/components/home/MenuConfiguracoesComercio.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO, OPERACIONAL] },
    name: "configuracoes.init",
    path: "/config",
  },
];
