<template>
  <span :class="['d-inline-block align-middle', { 'w-100': block }]">
    <BButton
      v-b-popover.hover.top="filter ? 'Remover filtro' : tooltip"
      :block="block"
      :class="[{ 'icon-btn': !$slots.default, borderless }, 'align-items-center justify-content-center d-flex']"
      :disabled="disabled || loading"
      :href="href"
      :pill="filter || pill"
      :size="size"
      :target="target"
      :to="to"
      :type="type"
      :variant="filter ? 'filter' : variant"
      @click="$emit('click')"
    >
      <span
        v-if="loading"
        data-loading
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i class="form-label spinner-border spinner-border-sm mb-0"></i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        v-else
        :class="['d-flex align-items-center justify-content-center']"
      >
        <i
          v-if="icon && !filter"
          :class="[icon, { 'mr-2': $slots.default }]"
        ></i>
        <i
          v-if="filter"
          class="ph-funnel-simple mr-2"
        ></i>
        <slot></slot>
        <i
          v-if="filter"
          class="ml-2 ph-x"
        ></i>
      </span>
    </BButton>
  </span>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: { BButton },
  props: {
    block: { default: false, type: Boolean },
    borderless: { default: false, type: Boolean },
    click: { default: () => {}, type: Function },
    disabled: { default: false, type: Boolean },
    filter: { default: false, type: Boolean },
    href: { default: "", type: String },
    icon: { default: "", type: String },
    loading: { type: Boolean },
    pill: { default: false, type: Boolean },
    size: { default: "md", type: String },
    target: { default: "_self", type: String },
    to: { default: "", type: [String, Object] },
    tooltip: { default: "", type: String },
    type: { default: "button", type: String },
    variant: { default: "default", type: String },
  },
};
</script>

<style scoped>
.icon-btn {
  aspect-ratio: 1/1;
}
</style>
