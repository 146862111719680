import {
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FINANCEIRO,
  GERENTE_OSORIO,
  CAIXA,
  GESTAO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";
export default [
  {
    component: () => import("@/components/contabilidade/contabilidade-home.vue"),
    name: "contabilidade",
    path: "/contabilidade",
  },
  {
    component: () => import("@/components/contabilidade/docsfiscais/docsfiscais-home.vue"),
    name: "contabilidade.docs",
    path: "/contabilidade/docs",
  },
  {
    component: () => import("@/components/contabilidade/relatorios/relatorios-home.vue"),
    name: "contabilidade.relatorios",
    path: "/contabilidade/relatorios",
  },
  {
    component: () => import("@/components/contabilidade/relatorios/home-bloco-k.vue"),
    name: "contabilidade.relatorios.bloco.k",
    path: "/contabilidade/relatorios/bloco-k",
  },
  {
    component: () => import("@/components/contabilidade/integracoes/integracoes-home.vue"),
    name: "contabilidade.integracoes",
    path: "/contabilidade/integracoes",
  },
  {
    component: () => import("@/components/compras/compras/gerenciar-notas-importadas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.docs.nfe.gerenciar",
    path: "/contabilidade/docs/nfe/gerenciar",
  },
  {
    path: "/contabilidade/docs/nfe/importar",
    name: "contabilidade.docs.nfe.importar",
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/importar-NFe.vue"),
  },
  {
    path: "/contabilidade/docs/nfe-industria/:id",
    name: "contabilidade.docs.nfe.importada-industria",
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/nota-importada-industria.vue"),
  },
  {
    path: "/contabilidade/docs/nfe-comercio/:id",
    name: "contabilidade.docs.nfe.importada",
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/compras/compras/nota-importada.vue"),
  },
  {
    component: () => import("@/components/logistica/cte/gerenciar-cte-importados.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.docs.cte.gerenciar",
    path: "/contabilidade/docs/cte/gerenciar",
  },
  {
    path: "/contabilidade/docs/cte/importar",
    name: "contabilidade.docs.cte.importar",
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    component: () => import("@/components/logistica/cte/importar-cte.vue"),
  },
  {
    component: () => import("@/components/compras/compras/servico/gerenciar-nfse-importadas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.docs.nfse",
    path: "/contabilidade/docs/nfse",
  },
  {
    component: () => import("@/components/compras/compras/servico/nfse-importada-industria.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.docs.nfse.industria",
    path: "/contabilidade/docs/nfse/industria/:id",
  },
  {
    component: () => import("@/components/financeiro/dominio-status.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.dominio.status",
    path: "/contabilidade/integracoes/dominio-status",
  },
  {
    component: () => import("@/components/contabilidade/integracoes/gerenciar-balancete.vue"),
    meta: { authorize: [ADMIN_SYS, FINANCEIRO_GERENTE, GESTAO] },
    name: "contabilidade.integracoes.balancete",
    path: "/contabilidade/integracoes/balancete",
  },
  {
    component: () => import("@/components/contabilidade/relatorios/totais-notas-fiscais.vue"),
    meta: { authorize: [ADMIN_SYS, FINANCEIRO_GERENTE, GESTAO] },
    name: "contabilidade.totais-notas-fiscais",
    path: "/contabilidade/relatorios/totais-notas-fiscais",
  },
  {
    component: () => import("@/components/contabilidade/docsfiscais/manifestacao.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        GERENTE_OSORIO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "contabilidade.docs.manifestacao",
    path: "/contabilidade/docs/manifestacao",
  },
  {
    component: () => import("@/components/contabilidade/relatorios/totais-notas-entrada.vue"),
    meta: { authorize: [ADMIN_SYS, FINANCEIRO_GERENTE, GESTAO] },
    name: "contabilidade.totais-notas-entrada",
    path: "/contabilidade/relatorios/totais-notas-entrada",
  },
];
