<template>
  <div class="row justify-content-center m-4">
    <div class="col">
      <code class="mx-auto p-4 bg-dark d-inline-block">
        <slot></slot>
      </code>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  code {
    -moz-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(5px);
    -webkit-box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;
    box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    max-width: 500px;
  }
}
</style>
