import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CLIENTE,
  CONTROLADORIA,
  EMISSOR_NFE,
  GESTAO,
  PUBLICIDADE,
  REPRESENTANTE,
  SUPERVISOR,
  SUPERVISOR_NACIONAL,
  VENDEDOR_COMERCIO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/configuracoes/emitente/form-emitente.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        REPRESENTANTE,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        VENDEDOR_COMERCIO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "configuracoes.emitentes.novo",
    path: "/configuracoes/emitentes/novo",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/editor-usuario.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        EMISSOR_NFE,
        GESTAO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "users.edit",
    path: "/configuracoes/users/:id/edit",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/gerenciar-usuarios.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        EMISSOR_NFE,
        GESTAO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "configuracoes.usuarios.gerenciar",
    path: "/configuracoes/usuarios/gerenciar",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/cadastrar-usuarios.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        EMISSOR_NFE,
        GESTAO,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "configuracoes.usuarios.cadastrar",
    path: "/configuracoes/usuarios/cadastrar",
  },
  {
    component: () => import("@/components/configuracoes/emitente/home-emitente.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, PUBLICIDADE, FINANCEIRO_GERENTE] },
    name: "configuracoes.emitentes",
    path: "/configuracoes/emitentes",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/home-usuarios.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, EMISSOR_NFE, GESTAO, FINANCEIRO_GERENTE] },
    name: "configuracoes.usuarios",
    path: "/configuracoes/usuarios",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/config-inicial.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "configuracoes.iniciais",
    path: "/configuracoes/iniciais",
  },
  {
    component: () => import("@/components/configuracoes/usuarios/config-info-fiscal.vue"),
    meta: { authorize: [EMISSOR_NFE, GESTAO] },
    name: "config.info.fiscal",
    path: "/config-info-fiscal",
  },
  {
    component: () => import("@/components/home/MenuConfiguracoes.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, EMISSOR_NFE, GESTAO, FINANCEIRO_GERENTE] },
    name: "configuracoes.home",
    path: "/configuracoes",
  },
  {
    component: () => import("@/components/minha-conta/minha-conta.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CLIENTE,
        CONTROLADORIA,
        EMISSOR_NFE,
        GESTAO,
        REPRESENTANTE,
        SUPERVISOR,
        SUPERVISOR_NACIONAL,
        VENDEDOR_COMERCIO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "minha-conta",
    path: "/configuracoes/minha-conta",
  },
];
