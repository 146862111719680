import { useNotification } from "@/composables/notify.js";
import axios from "axios";

const checkForUpdates = async () => {
  const { notify } = useNotification();
  let currentVersion;

  try {
    const response = await axios.get("/version.json");
    currentVersion = response.data.version;
  } catch (error) {
    console.error("Error fetching current version:", error);
    return;
  }

  const intervalId = setInterval(async () => {
    try {
      const response = await axios.get("/version.json");
      const { version } = response.data;

      if (version !== currentVersion) {
        notify({
          title: "Atualização Disponível",
          text: "Uma nova versão está disponível. Por favor, atualize a página para atualizar.",
          type: "info",
          duration: -1,
        });
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  }, 60000);
};

export default checkForUpdates;
