<template>
  <div class="position-relative">
    <div
      v-if="active"
      ref="container"
      :aria-busy="active"
      aria-label="Loading"
      class="align-items-center d-flex justify-content-center position-fixed top-0 left-0 w-100 h-100 z-[9999]"
      data-loading
      tabindex="0"
    >
      <div class="bg-white w-100 h-100 opacity-50 position-fixed" />
      <div class="position-relative">
        <svg
          height="64"
          stroke="#000"
          viewBox="0 0 38 38"
          width="64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            fill-rule="evenodd"
          >
            <g
              stroke-width="2"
              transform="translate(1 1)"
            >
              <circle
                cx="18"
                cy="18"
                r="18"
                stroke-opacity=".25"
              />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  dur="0.8s"
                  from="0 18 18"
                  repeatCount="indefinite"
                  to="360 18 18"
                  type="rotate"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, Ref } from "vue";

const props = defineProps({ active: { type: Boolean } });
const container: Ref<HTMLDivElement | null> = ref(null);

function focusIn(event: FocusEvent) {
  if (!props.active || event.target === null || container.value === null) return;
  const target = event.target as HTMLElement;
  if (target === container.value || container.value.contains(target)) return;
  const parent = container.value.parentElement;
  if (parent && parent.contains(target)) {
    event.preventDefault();
    container.value.focus();
  }
}

onMounted(() => document.addEventListener("focusin", focusIn));
onBeforeUnmount(() => document.removeEventListener("focusin", focusIn));
</script>

<style scoped>
.z-\[9999\] {
  z-index: 9999;
}
.position-fixed {
  position: fixed;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
</style>
