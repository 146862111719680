import { reactive } from "vue";
import Cookies from "js-cookie";

const state = reactive({
  emitente: {},
  emitenteId: Cookies.get("emitente_id") || "",
  emitentes: [],
});

const getEmitente = () => state.emitente;
const getEmitenteId = () => state.emitenteId;
const getEmitentes = () => state.emitentes;

const fetchEmitenteFromCookie = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/emitentes/${state.emitenteId}`)
      .then((response) => {
        if (Number.isInteger(state.identificador)) {
          Cookies.set("emitente_id", state.identificador);
        }
        state.emitente = response.data.data;
        resolve(response.data.data);
      })
      .catch((error) => reject(error));
  });
};

const fetchEmitentes = () => {
  axios
    .get("/api/emitentes")
    .then((response) => {
      state.emitentes = response.data.data;
    })
    .catch((error) => console.error(error));
};

const setEmitente = (emitente) => {
  if (Number.isInteger(emitente.identificador)) {
    Cookies.set("emitente_id", emitente.identificador);
    state.emitente = emitente;
  }
};

const clearEmitente = () => {
  state.emitente = {};
};

export default {
  clearEmitente,
  fetchEmitenteFromCookie,
  fetchEmitentes,
  getEmitente,
  getEmitenteId,
  getEmitentes,
  setEmitente,
  state,
};
