<template>
  <div>
    <LayoutButton
      id="popover-date"
      icon="ph-calendar-blank"
    >
      Data
    </LayoutButton>
    <BPopover
      ref="popover"
      placement="bottom"
      target="popover-date"
      triggers="click blur"
    >
      <template #title>Selecionar data</template>

      <div class="py-2">
        <div class="row no-gutters">
          <div class="col-auto">
            Data Inicial
            <Datepicker
              v-model="date.start"
              bootstrap-styling
              class="pointer"
              :disabled-dates="disabledStart"
              format="dd/MM/yyyy"
              :full-month-name="false"
              :highlighted="highlightedStart"
              :language="pt"
              :placeholder="placeholder"
              style="width: 248px"
              @closed="onSelectStart()"
            />
          </div>
        </div>
        <div class="mb-2"></div>
        <div class="row no-gutters">
          <div class="col-auto">
            Data Final
            <Datepicker
              v-model="date.end"
              bootstrap-styling
              class="mb-3 pointer"
              :disabled-dates="disabledEnd"
              format="dd/MM/yyyy"
              :full-month-name="false"
              :highlighted="highlightedEnd"
              :language="pt"
              :placeholder="placeholder"
              style="width: 248px"
              @closed="onSelectEnd()"
            />
          </div>
        </div>
      </div>

      <div class="text mb-3">
        <div class="mb-2">
          <LayoutButton
            class=""
            pill
            size="sm"
            variant="outline-primary"
            @click="onSubmitToday()"
          >
            Hoje
          </LayoutButton>
          <LayoutButton
            pill
            size="sm"
            variant="outline-primary"
            @click="onSubmitYesterday()"
          >
            Ontem
          </LayoutButton>
          <LayoutButton
            pill
            size="sm"
            variant="outline-primary"
            @click="onSubmitTodayYesterday()"
          >
            Hoje e ontem
          </LayoutButton>
        </div>
        <LayoutButton
          pill
          size="sm"
          variant="outline-primary"
          @click="onSubmitWeek()"
        >
          1 semana
        </LayoutButton>
        <LayoutButton
          pill
          size="sm"
          variant="outline-primary"
          @click="onSubmitMonth()"
        >
          1 mês
        </LayoutButton>
      </div>

      <LayoutButton
        block
        icon="ph-funnel-simple"
        size="sm"
        variant="primary"
        @click="onSubmitFilterDate"
      >
        {{ generateLabelSubmitFilter }}
      </LayoutButton>
    </BPopover>
    <LayoutButton
      v-if="toolbarState.date.start && toolbarState.date.end"
      filter
      @click="onClearDate"
    >
      {{ generateLabelButtonFilter }}
    </LayoutButton>
  </div>
</template>

<script>
import { ptBR } from "vuejs-datepicker/dist/locale";
import { toRaw } from "vue";
import Datepicker from "vuejs-datepicker";
import mixinToolbar from "@/components/comum/layout/toolbar/mixinToolbar.js";
import moment from "moment";

export default {
  components: { Datepicker },
  mixins: [mixinToolbar],
  data() {
    return {
      date: {
        end: "",
        start: "",
      },
      pt: ptBR,
    };
  },
  computed: {
    disabledEnd() {
      return {
        from: this.addDays(this.date.start, 30),
      };
    },
    disabledStart() {
      return {
        to: this.subtractDays(this.date.end, 30),
      };
    },
    generateLabelButtonFilter() {
      const date = this.toolbarState.date;

      switch (true) {
        case this.isSameDay(date) && this.isToday(date):
          return `Hoje, ${moment(date.start).format("D [de] MMMM")}`;
        case this.isYesterday(date):
          return `Ontem, ${moment(date.start).format("D [de] MMMM")}`;
        case !this.isSameDay(date) && this.isSameMonth(date) && this.isSameYear(date):
          return `${moment(date.start).format("D")}–${moment(date.end).format("D [de] MMMM")}`;
        case this.isSameDay(date) && this.isSameMonth(date) && this.isSameYear(date) && !this.isThisYear(date):
          return `${moment(date.start).format("D [de] MMMM [de] YYYY")}`;
        case this.isSameDay(date) && this.isSameMonth(date) && this.isSameYear(date) && this.isThisYear(date):
          return `${moment(date.start).format("D [de] MMMM")}`;
        case this.isSameMonth(date) && this.isSameYear(date):
          return `${moment(date.start).format("D")}–${moment(date.end).format("D [de] MMMM")}`;
        case !this.isSameMonth(date) && this.isSameYear(date):
          return `${moment(date.start).format("D MMM")}–${moment(date.end).format("D MMM")}`;
        case !this.isSameYear(date):
          return `${moment(date.start).format("D MMM YYYY")}–${moment(date.end).format("D MMM YYYY")}`;
        default:
          return "Data";
      }
    },
    generateLabelSubmitFilter() {
      const date = this.date;

      switch (true) {
        case !date.start && !date.end:
          return "Filtrar";
        case this.isToday(date):
          return "Filtrar hoje";
        case this.isYesterday(date):
          return "Filtrar ontem";
        case this.isEndDateToday(date) && this.dateDifference(date) === 1:
          return "Filtrar hoje e ontem";
        case this.isEndDateToday(date) && this.dateDifference(date) === 7:
          return "Filtrar última semana";
        case this.isEndDateToday(date) && this.dateDifference(date) > 1:
          return `Filtrar últimos ${this.dateDifference(date)} dias`;
        case this.isSameDay(date) && this.isSameMonth(date) && this.isSameYear(date) && !this.isThisYear(date):
          return `${moment(date.start).format("D [de] MMMM [de] YYYY")}`;
        case this.isSameDay(date) && this.isSameMonth(date) && this.isSameYear(date) && this.isThisYear(date):
          return `Filtrar ${moment(date.start).format("D [de] MMMM")}`;
        case this.isSameMonth(date):
          return `Filtrar ${moment(date.start).format("D")}–${moment(date.end).format("D [de] MMMM")}`;
        case !this.isSameMonth(date) && this.isSameYear(date):
          return `Filtrar ${moment(date.start).format("D MMM")}–${moment(date.end).format("D MMM")}`;
        case !this.isSameYear(date):
          return `Filtrar ${moment(date.start).format("D MMM YYYY")}–${moment(date.end).format("D MMM YYYY")}`;
        default:
          return "Filtrar";
      }
    },
    highlightedEnd() {
      return {
        from: this.date.start, // Highlight all dates after specific date
        to: this.date.end, // Highlight all dates up to specific date
      };
    },
    highlightedStart() {
      return {
        from: this.date.start,
        to: this.date.end,
      };
    },
    placeholder() {
      return moment(this.today).format("DD/MM/YYYY");
    },
    today() {
      return moment().startOf("day").toDate();
    },
    year() {
      return moment().startOf("year").toDate();
    },
    yesterday() {
      return moment().startOf("day").subtract(1, "days").toDate();
    },
  },

  mounted() {
    this.$root.$on("bv::popover::show", (bvEventObj) => {
      if (bvEventObj.target.id === "popover-date") {
        if (this.date.start && this.date.end) {
          this.date.start = this.generateRawDate(this.toolbarState.date.start);
          this.date.end = this.generateRawDate(this.toolbarState.date.end);
        }
      }
    });
  },
  methods: {
    addDays(date, days) {
      return moment(date).add(days, "days").toDate();
    },
    addMonths(date, months) {
      return moment(date).add(months, "months").toDate();
    },
    addWeeks(date, weeks) {
      return moment(date).add(weeks, "weeks").toDate();
    },
    closePopover() {
      this.$refs.popover.$emit("close");
    },
    dateDifference(date) {
      return moment(date.end).diff(moment(date.start), "days");
    },
    formatDateISO(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    generateFormattedDate() {
      this.date = {
        end: this.formatDateISO(this.date.end),
        start: this.formatDateISO(this.date.start),
      };
    },
    generateRawDate(dateString) {
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day);
    },

    isEndDateToday(date) {
      return moment(date.end).isSame(this.today);
    },
    isSameDay(date) {
      return moment(date.start).isSame(date.end, "day");
    },
    isSameMonth(date) {
      return moment(date.start).isSame(date.end, "month");
    },
    isSameYear(date) {
      return moment(date.start).isSame(date.end, "year");
    },
    isThisYear(date) {
      return moment(date.end).startOf("year").isSame(this.year) && moment(date.start).startOf("year").isSame(this.year);
    },
    isToday(date) {
      return moment(date.end).isSame(this.today) && moment(date.start).isSame(this.today);
    },
    isYesterday(date) {
      return moment(date.end).isSame(this.yesterday) && moment(date.start).isSame(this.yesterday);
    },
    onClearDate() {
      this.date = { end: "", start: "" };
      this.storeDate();
      this.$emit("filter-date");
    },
    onSelectEnd() {
      if (!this.date.start) this.date.start = this.date.end;
      if (this.date.start > this.date.end) this.date.start = this.date.end;
    },
    onSelectStart() {
      if (!this.date.end) this.date.end = this.date.start;
      if (this.date.start > this.date.end) this.date.end = this.date.start;
    },
    onSubmitFilterDate() {
      this.generateFormattedDate();
      this.storeDate();
      this.$emit("filter-date");
      this.closePopover();
    },
    onSubmitMonth() {
      const month = this.subtractMonths(this.today, 1);
      this.date.start = month;
      this.date.end = this.formatDateISO(this.today);
      this.generateFormattedDate();
      this.storeDate();
      this.onSubmitFilterDate();
      this.closePopover();
    },
    onSubmitToday() {
      this.date.start = this.today;
      this.date.end = this.today;
      this.generateFormattedDate();
      this.storeDate();
      this.onSubmitFilterDate();
      this.closePopover();
    },
    onSubmitTodayYesterday() {
      this.date.start = this.yesterday;
      this.date.end = this.today;
      this.generateFormattedDate();
      this.storeDate();
      this.onSubmitFilterDate();
      this.closePopover();
    },
    onSubmitWeek() {
      const week = this.subtractWeeks(this.today, 1);
      this.date.start = week;
      this.date.end = this.formatDateISO(this.today);
      this.generateFormattedDate();
      this.storeDate();
      this.onSubmitFilterDate();
      this.closePopover();
    },
    onSubmitYesterday() {
      this.date.start = this.yesterday;
      this.date.end = this.yesterday;
      this.generateFormattedDate();
      this.storeDate();
      this.onSubmitFilterDate();
      this.closePopover();
    },
    storeDate() {
      this.date.isFiltered = true;
      this.storeToolbarDate(structuredClone(toRaw(this.date)));
    },
    subtractDays(date, days) {
      return moment(date).subtract(days, "days").toDate();
    },
    subtractMonths(date, months) {
      return moment(date).subtract(months, "months").toDate();
    },
    subtractWeeks(date, weeks) {
      return moment(date).subtract(weeks, "weeks").toDate();
    },
  },
};
</script>

<style lang="scss" scoped>
$circle-size: 12.67px;
.date-circle {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  background-color: #3844db;
}
.date-line {
  border-right: 2.5px solid #3844db;
  height: $circle-size;
  width: 7.5px;
}
.date-spacing {
  height: $circle-size;
}
.popover {
  max-width: 295px !important;
}
</style>
