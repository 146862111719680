<template>
  <div>
    <Breadcrumb :items="breadcrumbs" />
    <div class="row row-cols-1 row-cols-md-2">
      <template v-for="card in cards">
        <CardSubmenu
          v-if="card.show"
          :key="card.header.title"
          :card="card"
        />
      </template>
      <slot name="custom-cards"></slot>
    </div>
  </div>
</template>

<script>
import CardSubmenu from "@/components/comum/cards/CardSubmenu.vue";

export default {
  components: { CardSubmenu },
  props: { breadcrumbs: { required: true, type: Array }, cards: { required: true, type: Array } },
};
</script>
