import {
  ADMINISTRADOR,
  REPRESENTANTE,
  SUPERVISOR,
  CLIENTE,
  FATURAMENTO_NFE,
  FATURAMENTO,
  CONTABIL,
  CREDITO_COBRANCA,
  LOGISTICA,
  COMPRAS,
  LABORATORIO,
  FINANCEIRO,
  SUPERVISOR_NACIONAL,
  CONTROLADORIA,
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  VENDEDOR_COMERCIO,
  EMISSOR_NFE,
  REVOGADO,
  PUBLICIDADE,
  GERENTE_COMERCIAL,
  GERENTE_DE_VENDAS,
  TINTOMETRICO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/rh/MenuRH.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh",
    path: "/rh",
  },
  {
    component: () => import("@/components/rh/SubmenuRHCadastros.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh.cadastros",
    path: "/rh/cadastros",
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncionarios.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh.cadastros.funcionarios",
    path: "/rh/cadastros/funcionarios",
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncionarios.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh.cadastros.funcionarios.novo",
    path: "/rh/cadastros/funcionarios/novo",
    props: { showModalStaffCreate: true },
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncoes.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh.cadastros.funcoes",
    path: "/rh/cadastros/funcoes",
  },
  {
    component: () => import("@/components/rh/ViewRHCadastrosFuncoes.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "rh.cadastros.funcoes.novo",
    path: "/rh/cadastros/funcoes/novo",
  },
];
