import { reactive } from "vue";

const initialState = {
  customer: {
    id: "",
    name: "",
  },
  date: {
    end: "",
    start: "",
  },
  search: "",
};

const state = reactive({ ...initialState });

export default {
  actions: {
    clearToolbarState: () => {
      Object.assign(state, initialState);
    },
    storeToolbarCustomer: (payload) => {
      state.customer = payload;
    },
    storeToolbarDate: (payload) => {
      state.date = payload;
    },
    storeToolbarSearch: (payload) => {
      state.search = payload;
    },
  },
  getters: {
    toolbarState: () => {
      return state;
    },
  },
};
