<template>
  <div :data-loading="isLoading">
    <Notifications />
    <RouterView />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import appStore from "@/store/app.js";
import authStore from "@/store/auth.js";
import emitenteStore from "@/store/emitente.js";

const isLoading = computed(() => appStore.loadingCount > 0);

onMounted(async () => {
  appStore.state.loadingCount += 1;
  if (authStore.isAuthenticated()) {
    await authStore.currentUserRequest();
    if (emitenteStore.getEmitenteId()) {
      await emitenteStore.fetchEmitenteFromCookie();
    }
  }
  appStore.state.loadingCount -= 1;
  appStore.state.mounted = true;
});
</script>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="@/vendor/libs/sweet-modal-vue/sweet-modal-vue.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<style src="@/vendor/styles/pages/shop.scss" lang="scss"></style>
<style src="@/vendor/libs/sweet-modal-vue/sweet-modal-vue.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
