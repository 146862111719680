import {
  ADMINISTRADOR,
  ADMIN_SYS,
  CONTROLADORIA,
  FINANCEIRO_GERENTE,
  GESTAO,
  REPRESENTANTE,
  SUPERVISOR_NACIONAL,
  CAIXA,
} from "@/constants/profiles.js";

export default [
  {
    path: "/dashboard",
    name: "dashboard.home",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL, CAIXA],
    },
    component: () => import("@/components/home/MenuDashboard.vue"),
  },
  {
    path: "/dashboard/vendas",
    name: "dashboard.vendas",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL],
    },
    component: () => import("@/components/dashboards/vendas/dashboard-vendas.vue"),
  },
  {
    path: "/dashboard/vendas-representante",
    name: "dashboard.vendas-representante",
    meta: {
      authorize: [ADMIN_SYS, ADMINISTRADOR, SUPERVISOR_NACIONAL, REPRESENTANTE, CAIXA],
    },
    component: () => import("@/components/dashboards/vendas/dashboard-vendas-representante.vue"),
  },
  {
    path: "/dashboard/otif",
    name: "dashboard.otif",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL],
    },
    component: () => import("@/components/logistica/dashboard/dashboard-otif.vue"),
  },
  {
    path: "/dashboard/oee",
    name: "dashboard.oee",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/dashboard-producao.vue"),
  },
  {
    path: "/dashboard/financeiro",
    name: "dashboard.financeiro",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL],
    },
    component: () => import("@/components/dashboards/financeiro/dashboard-financeiro.vue"),
  },
  {
    path: "/dashboard/financeiro/fleuriet",
    name: "dashboard.financeiro.fleuriet",
    meta: {
      authorize: [ADMINISTRADOR, ADMIN_SYS, CONTROLADORIA, FINANCEIRO_GERENTE, SUPERVISOR_NACIONAL],
    },
    component: () => import("@/components/dashboards/financeiro/indicadores/financeiro-fleuriet.vue"),
  },
];
