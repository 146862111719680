import {
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FINANCEIRO,
  GERENTE_OSORIO,
  CAIXA,
  GESTAO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";
export default [
  {
    component: () => import("@/components/financeiro/cadastros/centro-custo/centro-custo.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.centro-custo",
    path: "/financeiro/cadastros/centro-custo",
  },
  {
    component: () => import("@/components/financeiro/cadastros/contas-bancarias/contas-bancarias.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.contas-bancarias",
    path: "/financeiro/cadastros/contas-bancarias",
  },
  {
    component: () => import("@/components/financeiro/transacoes/pagar/gerenciar-contas-pagar.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.pagar",
    path: "/financeiro/transacoes/contas-pagar",
  },
  {
    component: () => import("@/components/financeiro/transacoes/portal-cliente/contas-pagar-historico-portal.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.contas-historico-portal",
    path: "/financeiro/contas-pagar-historico-portal",
  },
  {
    component: () => import("@/components/financeiro/transacoes/portal-cliente/contas-pagar-portal.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.contas-portal",
    path: "/financeiro/contas-pagar-portal",
  },
  {
    component: () => import("@/components/financeiro/transacoes/receber/gerenciar-contas-receber.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONTROLADORIA,
        FINANCEIRO,
        CREDITO_COBRANCA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        CAIXA,
        GESTAO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "financeiro.receber",
    path: "/financeiro/transacoes/contas-receber",
  },
  {
    component: () => import("@/components/financeiro/transacoes/pagar/editor-conta-pagar.vue"),
    meta: { authorize: [ADMIN_SYS, GESTAO, CONTROLADORIA, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.pagar.edit",
    path: "/financeiro/transacoes/contas-pagar/editar/:id",
  },
  {
    component: () => import("@/components/financeiro/transacoes/pagar/editor-conta-pagar.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.pagar.novo",
    path: "/financeiro/transacoes/contas-pagar/novo",
  },
  {
    component: () => import("@/components/financeiro/docsfiscais/home.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.docs",
    path: "/financeiro/docs",
  },
  {
    component: () => import("@/components/compras/compras/gerenciar-notas-importadas.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.docs.nfe.gerenciar",
    path: "/financeiro/docs/nfe/gerenciar",
  },
  {
    path: "/financeiro/docs/nfe/importar",
    name: "financeiro.docs.nfe.importar",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/importar-NFe.vue"),
  },
  {
    component: () => import("@/components/logistica/cte/gerenciar-cte-importados.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.docs.cte.gerenciar",
    path: "/financeiro/docs/cte/gerenciar",
  },
  {
    path: "/financeiro/docs/cte/importar",
    name: "financeiro.docs.cte.importar",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    component: () => import("@/components/logistica/cte/importar-cte.vue"),
  },
  {
    component: () => import("@/components/compras/compras/servico/gerenciar-nfse-importadas.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.docs.nfse",
    path: "/financeiro/docs/nfse",
  },
  {
    component: () => import("@/components/compras/compras/servico/nfse-importada-industria.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.docs.nfse.industria",
    path: "/financeiro/docs/nfse/industria/:id",
  },
  {
    path: "/financeiro/docs/nfe-industria/:id",
    name: "financeiro.docs.nfe.importada-industria",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/nota-importada-industria.vue"),
  },
  {
    path: "/financeiro/docs/nfe-comercio/:id",
    name: "financeiro.docs.nfe.importada",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/nota-importada.vue"),
  },
  {
    component: () => import("@/components/financeiro/transacoes/receber/editor-conta-receber.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, CAIXA, FINANCEIRO_GERENTE] },
    name: "financeiro.receber.novo",
    path: "/financeiro/transacoes/contas-receber/novo",
  },
  {
    component: () => import("@/components/financeiro/boletos/gerenciar-boletos.vue"),
    meta: { authorize: [ADMIN_SYS] },
    name: "financeiro.boletos.gerenciar",
    path: "/financeiro/boletos/gerenciar",
  },
  {
    component: () => import("@/components/financeiro/cadastros/cheques/gerenciar-cheques.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cheques",
    path: "/financeiro/cadastros/cheques",
  },
  {
    component: () => import("@/components/financeiro/boletos/gerenciar-remessa.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.remessas",
    path: "/financeiro/boletos/remessas",
  },
  {
    component: () => import("@/components/financeiro/boletos/gerenciar-remessa-show.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.edit",
    path: "/financeiro/boletos/remessas-show/editar/:id",
  },
  {
    component: () => import("@/components/financeiro/boletos/gerenciar-retornos.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.gerenciaretornos",
    path: "/financeiro/boletos/retornos",
  },
  {
    component: () => import("@/components/financeiro/boletos/home-boletos.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.home.boletos",
    path: "/financeiro/boletos",
  },
  {
    component: () => import("@/components/financeiro/cadastros/home-cadastros.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.home.cadastros",
    path: "/financeiro/cadastros",
  },
  {
    component: () => import("@/components/financeiro/relatorios/home-relatorios.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.home.relatorios",
    path: "/financeiro/relatorios",
  },
  {
    component: () => import("@/components/financeiro/transacoes/HomeTransacoes.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONTROLADORIA,
        FINANCEIRO,
        CREDITO_COBRANCA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
        GESTAO,
        CAIXA,
      ],
    },
    name: "financeiro.transacoes",
    path: "/financeiro/transacoes",
  },
  {
    component: () => import("@/components/financeiro/boletos/importar-retornos.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.retornos",
    path: "/financeiro/boletos/retornos/importar",
  },
  {
    component: () => import("@/components/home/MenuFinanceiro.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        CONTROLADORIA,
        FINANCEIRO,
        CREDITO_COBRANCA,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        FINANCEIRO_GERENTE,
        GESTAO,
        CAIXA,
      ],
    },
    name: "financeiro.home.financeiro",
    path: "/financeiro",
  },
  {
    component: () => import("@/components/financeiro/boletos/nova-remessa.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.nova-remessa",
    path: "/financeiro/boletos/remessas/nova",
  },
  {
    component: () => import("@/components/financeiro/cadastros/cheques/novo-cheque.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.novo.cheque",
    path: "/financeiro/cadastros/cheques/novo-cheque",
  },
  {
    component: () => import("@/components/financeiro/cadastros/cheques/novo-cheque.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, GESTAO, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.edit.cheque",
    path: "/financeiro/cadastros/cheques/edit-cheque/:id",
  },
  {
    component: () => import("@/components/financeiro/cadastros/contas-bancarias/contas-bancarias-novo.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.contas-bancarias-novo",
    path: "/financeiro/cadastros/contas-bancarias-novo",
  },
  {
    component: () => import("@/components/financeiro/cadastros/contas-bancarias/contas-bancarias-novo.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.contas-bancarias.edit",
    path: "/financeiro/cadastros/contas-bancarias/editar/:id",
  },
  {
    component: () => import("@/components/financeiro/cadastros/natureza-financeira/natureza-financeira.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.natureza-financeira",
    path: "/financeiro/cadastros/natureza-financeira",
  },
  {
    component: () => import("@/components/financeiro/cadastros/planos-de-contas/planos-de-contas.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.cadastros.planos-de-contas",
    path: "/financeiro/cadastros/planos-de-contas",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-balancete-gerencial.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.relatorio.balancete",
    path: "/financeiro/relatorios/balancete-gerencial",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-comissao.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, GESTAO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },

    name: "financeiro.relatorio.comissaorep",
    path: "/financeiro/relatorios/comissao/representantes",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-comissao-clientes.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, CONSULTA_FINANCEIRO, GESTAO, FINANCEIRO_GERENTE] },
    name: "financeiro.relatorio.comissaorepcli",
    path: "/financeiro/relatorios/comissao/clientes",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-comissao-produto.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, CONSULTA_FINANCEIRO, GESTAO, FINANCEIRO_GERENTE] },
    name: "financeiro.relatorio.comissao.produtos",
    path: "/financeiro/relatorios/comissao/produtos",
  },
  {
    component: () => import("@/components/financeiro/boletos/retorno-print.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, CONSULTA_FINANCEIRO, GESTAO, FINANCEIRO_GERENTE] },
    name: "financeiro.boletos.retorno.print",
    path: "/financeiro/boletos/retorno/relatorio/:id",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-contas-pagar-database.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.relatorio.contaspagar",
    path: "/financeiro/relatorios/contas-pagar",
  },
  {
    component: () => import("@/components/financeiro/relatorios/relatorio-contas-receber-database.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, FINANCEIRO, CONSULTA_FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "financeiro.relatorio.contas-receber",
    path: "/financeiro/relatorios/contas-receber",
  },
];
