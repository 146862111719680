<template>
  <dialog ref="dialog">
    <div class="dialog-content d-flex flex-column p-4 position-relative">
      <div class="dialog-header align-items-center d-flex justify-content-between"><slot name="title"></slot></div>
      <div class="dialog-body"><slot></slot></div>
      <div class="dialog-footer d-flex justify-content-end"><slot name="buttons"></slot></div>
    </div>
    <button
      class="close-modal position-absolute"
      type="button"
      @click="onCloseDialog"
    >
      ✖
    </button>
  </dialog>
</template>

<script setup>
import { ref } from "vue";

const dialog = ref(null);
const emit = defineEmits(["close"]);
const closeDialog = () => {
  emit("close");
  dialog.value.close();
};
const openDialog = () => dialog.value.showModal();
defineExpose({ closeDialog, openDialog });
const props = defineProps({
  close: Function,
});
const onCloseDialog = () => {
  if (props.close) {
    props.close();
  } else {
    closeDialog();
  }
};
</script>

<style scoped>
dialog {
  border: none;
  border-radius: 10px;
  background-color: #fff;
}
.dialog-content {
  gap: 1.5rem;
}
.close-modal {
  top: 1.5rem;
  right: 1.5rem;
  font-size: 16px;
  background: none;
  border: none;
  color: #666;
}
</style>
