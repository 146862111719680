import { reactive } from "vue";
import Cookies from "js-cookie";

const state = reactive({ access_token: Cookies.get("access_token") || "", current_user: {}, status: "" });

const getCurrentUser = () => state.current_user;
const isAuthenticated = () => !!state.access_token;

const authLogout = async () => {
  Cookies.remove("access_token");
  Cookies.remove("emitente_id");
  await axios.post("/logout");
  state.access_token = "";
};

const authRequest = async (payload) => {
  let actionUrl = "/login";
  const remember = payload.remember ? payload.remember : false;
  const data = { email: payload.email, password: payload.password };
  if (payload.action === "register") {
    actionUrl = "/register";
    data.name = payload.name;
    data.password_confirmation = payload.password_confirmation;
  }
  if (payload.action === "password-reset") {
    actionUrl = "/password/reset";
    data.password_confirmation = payload.password_confirmation;
    data.token = payload.token;
  }
  const response = await axios.post(actionUrl, data);
  const access_token = "Bearer " + response.data.access_token;
  Cookies.set("access_token", access_token, { expires: remember ? 365 : 1 });
  axios.defaults.headers.common["Authorization"] = access_token;
  state.access_token = access_token;
  currentUserRequest();
};

const currentUserRequest = async () => {
  try {
    const response = await axios.get("/api/user");
    state.current_user = response.data.data;
  } catch (err) {
    authLogout();
  }
};

export default {
  authLogout,
  authRequest,
  currentUserRequest,
  getCurrentUser,
  isAuthenticated,
  state,
};
