<template>
  <span :class="{ 'text-muted': textMuted }">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
</template>
<script>
export default {
  props: {
    textMuted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
