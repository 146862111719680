import layoutHelpers from "@/layout/helpers.js";

export default function () {
  return {
    // Base url
    baseUrl: "/",

    // Layout helpers
    layoutHelpers,

    // Layout navbar color
    get layoutNavbarBg() {
      return "navbar-theme";
    },

    // Animate scrollTop
    scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return;
      const start = element.scrollTop;
      const change = to - start;
      const startDate = +new Date();

      const easeInOutQuad = (currentTime, startValue, changeInValue, duration) => {
        currentTime /= duration / 2;
        if (currentTime < 1) return (changeInValue / 2) * currentTime * currentTime + startValue;
        currentTime--;
        return (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };

      animateScroll();
    },
  };
}
