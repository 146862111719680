import { ADMIN_SYS, CLIENTE, REPRESENTANTE, SUPERVISOR, VENDEDOR_COMERCIO } from "@/constants/profiles.js";
import authStore from "@/store/auth.js";
import emitenteStore from "@/store/emitente.js";

export default {
  currentEmitente: emitenteStore.getEmitente,
  currentEmitenteIsComercio() {
    return this.currentEmitente.cnae == 47;
  },
  currentEmitenteIsRegimeNormal() {
    return this.currentEmitente.regime_tributario == 3;
  },
  currentEmitenteIsRegimeSimpleNacional() {
    return this.currentEmitente.regime_tributario == 1;
  },
  currentUser: authStore.getCurrentUser,
  currentUserIsAdmin() {
    return ![CLIENTE, REPRESENTANTE, SUPERVISOR, VENDEDOR_COMERCIO].includes(this.currentUser.role);
  },
  currentUserIsAdminSys() {
    return this.currentUser.role === ADMIN_SYS;
  },
  currentUserIsRepresentante() {
    return [REPRESENTANTE, VENDEDOR_COMERCIO].includes(this.currentUser.role);
  },
  currentUserIsSupervisor() {
    return this.currentUser.role === SUPERVISOR;
  },
  emitente: emitenteStore.getEmitente /*deprecated*/,
  user: authStore.getCurrentUser /*deprecated*/,
};
