<template>
  <div class="col-md-6">
    <BCard no-body>
      <BCardBody>
        <h4 class="card-title">{{ card.header.title }}</h4>
        <p class="card-text text-muted">{{ card.header.description }}</p>
      </BCardBody>
      <BListGroup flush>
        <BListGroupItem
          v-if="card.buttons"
          class="py-3"
        >
          <template v-for="(button, index) in card.buttons">
            <LayoutButton
              v-if="!button.hide"
              :key="button.title"
              class="mr-3 my-2"
              :icon="button.icon"
              :to="button.link"
              :variant="index === 0 ? 'secondary' : 'default'"
            >
              {{ button.title }}
            </LayoutButton>
          </template>
        </BListGroupItem>
        <template v-if="card.stats">
          <BListGroupItem
            v-for="stat in card.stats"
            :key="stat.value"
          >
            <span class="mr-2">{{ stat.title }}</span>
            <BBadge
              pill
              variant="default"
            >
              {{ stat.value }}
            </BBadge>
          </BListGroupItem>
        </template>
      </BListGroup>
      <BCardBody v-if="card.references">
        <a
          v-for="reference in card.references"
          :key="reference.title"
          class="card-link"
          :href="reference.link"
          target="_blank"
        >
          <span class="d-flex align-items-center">
            <span class="mr-1">{{ reference.title }}</span>
            <i class="ph-arrow-square-out"></i>
          </span>
        </a>
      </BCardBody>
    </BCard>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    card: { required: true, type: Object },
  },
};
</script>
