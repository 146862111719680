import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FATURAMENTO_NFE,
  FATURAMENTO,
  FINANCEIRO,
  GERENTE_OSORIO,
  LOGISTICA,
  REPRESENTANTE,
  SUPERVISOR_NACIONAL,
  SUPERVISOR,
  VENDEDOR_COMERCIO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/faturamento/vendas/acompanhar-vendas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        REPRESENTANTE,
        SUPERVISOR_NACIONAL,
        SUPERVISOR,
        VENDEDOR_COMERCIO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.acompanhar.vendas",
    path: "/e-commerce/vendas/acompanhar",
  },
  {
    component: () => import("@/components/faturamento/vendas/analise-credito.vue"),
    meta: {
      authorize: [ADMIN_SYS, ADMINISTRADOR, CONTABIL, CONTROLADORIA, CREDITO_COBRANCA, FINANCEIRO, FINANCEIRO_GERENTE],
    },
    name: "faturamento.analise-credito",
    path: "/faturamento/analise-credito/:cliente_id/:id_venda?/:representante_id?",
  },
  {
    component: () => import("@/components/faturamento/vendas/analise-credito-representante.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        FINANCEIRO,
        REPRESENTANTE,
        SUPERVISOR_NACIONAL,
        SUPERVISOR,
        VENDEDOR_COMERCIO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.analise-credito.representante",
    path: "/faturamento/analise-credito/representante",
  },
  {
    component: () => import("@/components/e-commerce/views/GerenciarVendas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
        SUPERVISOR_NACIONAL,
      ],
    },
    name: "faturamento.vendas.gerenciar",
    path: "/e-commerce/vendas/gerenciar",
  },
  {
    component: () => import("@/components/e-commerce/views/GerenciarMetas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.metas.gerenciar",
    path: "/faturamento/vendas/metas/gerenciar",
  },
  {
    component: () => import("@/components/e-commerce/views/GerenciarGrupoMetas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.grupometas.gerenciar",
    path: "/faturamento/vendas/grupo-metas/gerenciar",
  },
  {
    component: () => import("@/components/e-commerce/views/edit-metas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.metas.novo",
    path: "/faturamento/vendas/metas/novo",
  },
  {
    component: () => import("@/components/faturamento/vendas/objetivos-comerciais/home-objetivos-comerciais.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL, FINANCEIRO_GERENTE] },
    name: "vendas.objetivos.comerciais",
    path: "/e-commerce/vendas/objetivos-comerciais",
  },
  {
    component: () => import("@/components/faturamento/vendas/home-vendas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        REPRESENTANTE,
        SUPERVISOR_NACIONAL,
        SUPERVISOR,
        VENDEDOR_COMERCIO,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas",
    path: "/e-commerce/vendas",
  },
  {
    component: () => import("@/components/faturamento/vendas/supervisor/liberar-vendas.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTROLADORIA,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        SUPERVISOR,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.liberar.vendas",
    path: "/e-commerce/vendas/liberar-vendas",
  },
  {
    component: () => import("@/components/faturamento/vendas/objetivos-comerciais/objetivos-comerciais-form.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL, FINANCEIRO_GERENTE] },
    name: "vendas.objetivos-comerciais.novo",
    path: "/e-commerce/vendas/objetivos-comerciais/novo/:id?",
  },
  {
    component: () => import("@/components/faturamento/vendas/objetivos-comerciais/objetivos-comerciais-reps-form.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL, SUPERVISOR, FINANCEIRO_GERENTE] },
    name: "vendas.objetivos-comerciais.representante.novo",
    path: "/e-commerce/vendas/objetivos-comerciais/representante/novo/:id/:mes/:ano/:codigo_supervisor/:cod_representante/:id_objetivo?",
  },
  {
    component: () => import("@/components/faturamento/vendas/objetivos-comerciais/objetivos-comerciais.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, SUPERVISOR_NACIONAL, SUPERVISOR, FINANCEIRO_GERENTE] },
    name: "vendas.objetivos.comerciais.gerenciar",
    path: "/e-commerce/vendas/objetivos-comerciais/gerenciar/:tela?",
  },
  {
    component: () => import("@/components/faturamento/relatorios/produtos-bloqueio.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.produtos.bloqueio",
    path: "/e-commerce/vendas/produtos-bloqueio",
  },
  {
    component: () => import("@/components/faturamento/relatorios/produtos-liberados.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        FINANCEIRO,
        GERENTE_OSORIO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    name: "faturamento.vendas.produtos.liberados",
    path: "/e-commerce/vendas/produtos-liberados",
  },
];
