import { ADMIN_SYS } from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/ponto-de-venda/MenuPontoDeVenda.vue"),
    meta: { authorize: [ADMIN_SYS] },
    name: "ponto-de-venda",
    path: "/ponto-de-venda",
  },
];
