import {
  ADMINISTRADOR,
  REPRESENTANTE,
  SUPERVISOR,
  CLIENTE,
  FATURAMENTO_NFE,
  FATURAMENTO,
  CONTABIL,
  CREDITO_COBRANCA,
  LOGISTICA,
  COMPRAS,
  LABORATORIO,
  FINANCEIRO,
  SUPERVISOR_NACIONAL,
  CONTROLADORIA,
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  VENDEDOR_COMERCIO,
  EMISSOR_NFE,
  REVOGADO,
  PUBLICIDADE,
  GERENTE_COMERCIAL,
  GERENTE_DE_VENDAS,
  TINTOMETRICO,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    path: "/tintometrico",
    name: "tintometrico",
    component: () => import("@/components/home/MenuTintometrico.vue"),
  },
  {
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    path: "/tintometrico/cadastro-custo",
    name: "tintometrico.cadastro",
    component: () => import("@/components/tintometrico/cadastro.vue"),
  },
  {
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    path: "/tintometrico/colecoes-cores",
    name: "tintometrico.colecao",
    component: () => import("@/components/tintometrico/colecao.vue"),
  },
  {
    meta: {
      authorize: [
        ADMINISTRADOR,
        REPRESENTANTE,
        SUPERVISOR,
        CLIENTE,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LOGISTICA,
        COMPRAS,
        LABORATORIO,
        FINANCEIRO,
        SUPERVISOR_NACIONAL,
        CONTROLADORIA,
        ADMIN_SYS,
        CONSULTA_FINANCEIRO,
        VENDEDOR_COMERCIO,
        EMISSOR_NFE,
        REVOGADO,
        PUBLICIDADE,
        GERENTE_COMERCIAL,
        GERENTE_DE_VENDAS,
        TINTOMETRICO,
        FINANCEIRO_GERENTE,
      ],
    },
    path: "/tintometrico/margem-lucro",
    name: "tintometrico.lucro",
    component: () => import("@/components/tintometrico/lucro.vue"),
  },
];
