import {
  ADMIN_SYS,
  ADMINISTRADOR,
  CONTROLADORIA,
  EMISSOR_NFE,
  FATURAMENTO,
  FATURAMENTO_NFE,
  FINANCEIRO_GERENTE,
  GERENTE_OSORIO,
  GESTAO,
  LOGISTICA,
  OPERACIONAL,
  SUPERVISOR_NACIONAL,
} from "@/constants/profiles.js";

export default {
  children: [
    {
      component: () => import("@/components/faturamento/caixa/print-caixa.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
        ],
      },
      name: "faturamento.caixa.caixa-print",
      path: "/faturamento/caixa/caixa-print/:data",
    },
    {
      component: () => import("@/components/controladoria/cenarios/cenario-print.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          LOGISTICA,
        ],
      },
      name: "controladoria.cenario-print",
      path: "/controladoria/cenario-print/:id",
    },
    {
      component: () => import("@/components/faturamento/nfe/PrintBinario.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
        ],
      },
      name: "faturamento.nfe.print-binario",
      path: "/faturamento/nfe/:id",
    },
    {
      component: () => import("@/components/estoque/relatorios/produtos-custo-print.vue"),
      meta: { authorize: [ADMINISTRADOR, FINANCEIRO_GERENTE] },
      name: "estoque.relatorios.custo.print",
      path: "/estoque/relatorios/custos-totais-print",
    },
    {
      component: () => import("@/components/estoque/cadastros/produto/etiquetas-comercio/produtos-etiqueta.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
        ],
      },
      name: "estoque.etiquetas.print",
      path: "/estoque/produtos/etiquetas",
    },
    {
      component: () => import("@/components/logistica/romaneio/gerenciar-romaneio-show.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
        ],
      },
      name: "logistica.romaneio.romaneio-print",
      path: "/logistica/romaneio/romaneio-print/:id",
    },
    {
      component: () => import("@/components/logistica/romaneio/gerenciar-romaneio-show-resumo.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
        ],
      },
      name: "logistica.romaneio.romaneio-print-resumo",
      path: "/logistica/romaneio/resumo-romaneio-print/:id",
    },
    {
      component: () => import("@/components/faturamento/os/print-os.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          GESTAO,
          OPERACIONAL,
          EMISSOR_NFE,
          LOGISTICA,
        ],
      },
      path: "/faturamento/os/print/:id",
      name: "os.imprimir",
    },
    {
      component: () => import("@/components/dashboards/vendas/vendas-tempo-real.vue"),
      meta: {
        authorize: [
          ADMIN_SYS,
          ADMINISTRADOR,
          CONTROLADORIA,
          FATURAMENTO,
          FATURAMENTO_NFE,
          FINANCEIRO_GERENTE,
          GERENTE_OSORIO,
          LOGISTICA,
          SUPERVISOR_NACIONAL,
        ],
      },
      name: "dashboard.vendas.tempo-real-venda",
      path: "/dashboard/vendas/tempo-real",
    },
  ],
  component: () => import("@/layout/LayoutBlank.vue"),
  meta: { requiresAuth: true, requiresEmitente: true },
  path: "/",
};
