export default [
  {
    component: () => import("@/components/produtividade/MenuProdutividade.vue"),
    name: "menu.produtividade",
    path: "/produtividade",
  },
  {
    component: () => import("@/components/produtividade/SubmenuProdutividadeRelatorios.vue"),
    name: "produtividade.relatorios",
    path: "/produtividade/relatorios",
  },
  {
    component: () => import("@/components/produtividade/relatorios/ViewProdutividadeRelatoriosEquipe.vue"),
    name: "produtividade.relatorios.equipe",
    path: "/produtividade/relatorios/equipe",
  },
  {
    component: () => import("@/components/produtividade/relatorios/ViewProdutividadeRelatoriosEquipeFuncionario.vue"),
    name: "produtividade.relatorios.equipe.funcionario",
    path: "/produtividade/relatorios/equipe/:id",
  },
  {
    component: () => import("@/components/comum/ticket/tickets-list.vue"),
    name: "tickets",
    path: "/produtividade/tickets",
  },
  {
    component: () => import("@/components/comum/ticket/ticket-discussion.vue"),
    name: "tickets.discussion",
    path: "/produtividade/tickets/discussion/:id",
  },
  {
    component: () => import("@/components/comum/ticket/ticket-form.vue"),
    name: "tickets.create",
    path: "/produtividade/tickets/create",
  },
  {
    component: () => import("@/components/comum/ticket/ticket-form.vue"),
    name: "tickets.edit",
    path: "/produtividade/tickets/edit/:id",
  },
  {
    component: () => import("@/components/comum/ticket/tickets-kanban.vue"),
    name: "tickets.kanban",
    path: "/produtividade/kanban",
  },
  {
    component: () => import("@/components/agenda/ViewProdutividadeAgenda.vue"),
    name: "agenda",
    path: "/produtividade/agenda",
  },
];
