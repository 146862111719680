import {
  ADMIN_SYS,
  CONTROLADORIA,
  CONTABIL,
  COMPRAS,
  EMISSOR_NFE,
  GESTAO,
  CAIXA,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";
export default [
  {
    component: () => import("@/components/recebimento/home.vue"),
    name: "recebimento",
    path: "/recebimento",
  },
  {
    component: () => import("@/components/recebimento/docsfiscais/home.vue"),
    name: "recebimento.docs",
    path: "/recebimento/docs",
  },
  {
    component: () => import("@/components/compras/compras/gerenciar-notas-importadas.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    name: "recebimento.docs.nfe.gerenciar",
    path: "/recebimento/docs/nfe/gerenciar",
  },
  {
    path: "/recebimento/docs/nfe/importar",
    name: "recebimento.docs.nfe.importar",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/importar-NFe.vue"),
  },
  {
    path: "/recebimento/docs/nfe-industria/:id",
    name: "recebimento.docs.nfe.importada-industria",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/nota-importada-industria.vue"),
  },
  {
    path: "/recebimento/docs/nfe-comercio/:id",
    name: "recebimento.docs.nfe.importada",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    component: () => import("@/components/compras/compras/nota-importada.vue"),
  },
  {
    component: () => import("@/components/logistica/cte/gerenciar-cte-importados.vue"),
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    name: "recebimento.docs.cte.gerenciar",
    path: "/recebimento/docs/cte/gerenciar",
  },
  {
    path: "/recebimento/docs/cte/importar",
    name: "recebimento.docs.cte.importar",
    meta: { authorize: [ADMIN_SYS, CONTROLADORIA, CONTABIL, COMPRAS, EMISSOR_NFE, GESTAO, CAIXA, FINANCEIRO_GERENTE] },
    component: () => import("@/components/logistica/cte/importar-cte.vue"),
  },
];
